<template>
  <div>
    <button class="btn btn-primary float-right" @click="fetchItems"><b-icon icon="b-arrow-clockwise" /> Aktualisieren</button>
    <h2>{{ $t('views.users.userkeys.title') }}</h2>
    <p class="h5">{{ $t('views.users.userkeys.description') }}</p>
    <b-table :items="assignedKeys.items" :fields="assignedKeys.fields"
      :busy="assignedKeys.isLoading"
      striped
      hover
      show-empty
      :sort-by.sync="assignedKeys.sortBy" :sort-desc.sync="assignedKeys.sortDesc" @sort-changed="onAssignedKeysSortChanged">
      <template #cell(created)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(actions)="data">
        <b-button variant="danger" size="sm" @click="unassignKey(data.item.id)"><b-icon icon="trash" /> {{ $t('views.users.userkeys.buttons.removeassignement') }}</b-button>
      </template>
    </b-table>
    <h2>{{ $t('views.users.userkeys.unassigned.title') }}</h2>
    <p class="h5">{{ $t('views.users.userkeys.unassigned.description') }}</p>
    <b-table :items="unassignedKeys.items" :fields="unassignedKeys.fields"
      :busy="unassignedKeys.isLoading"
      striped
      hover
      show-empty
      :sort-by.sync="unassignedKeys.sortBy" :sort-desc.sync="unassignedKeys.sortDesc" @sort-changed="onUnassignedKeysSortChanged">
      <template #cell(created)="data">
        {{ formatDate(data.value) }}
      </template>
      <template #cell(actions)="data">
        <b-button variant="primary" size="sm" @click="assignKey(data.item.id)"><b-icon icon="plus" /> {{ $t('views.users.userkeys.buttons.addassignement') }}</b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import http from '@/$plugins/http/core'
import { STOREMODULE_ENDPOINTMODULE_MAP, STOREMODULE_ENDPOINT_MAP } from '@/constants'

export default {
  name: 'Users.Userkeys',
  mixins: [MixinEntityBase],
  data () {
    return {
      assignedKeys: {
        items: [],
        fields: [
          { key: 'created', label: 'Datum', sortable: true, class: 'w-25' },
          { key: 'serialNumber', label: 'Schlüssel-Id', sortable: true, class: 'w-50' },
          { key: 'doorUnlockCount', label: 'Anzahl Türöffnungen', sortable: true },
          { key: 'actions', label: 'Aktionen', class: 'text-right' }
        ],
        sortBy: 'created',
        sortDesc: true,
        isLoading: true
      },
      unassignedKeys: {
        items: [],
        fields: [
          { key: 'created', label: 'Datum', sortable: true, class: 'w-25' },
          { key: 'serialNumber', label: 'Schlüssel-Id', sortable: true, class: 'w-50' },
          { key: 'actions', label: 'Aktionen', class: 'text-right' }
        ],
        sortBy: 'created',
        sortDesc: true,
        isLoading: true
      }
    }
  },
  mounted () {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      this.assignedKeys.isLoading = true
      this.unassignedKeys.isLoading = true

      http({
        method: 'get',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.userkeys}/fromuser/${this.eParent.id}`
      }).then(response => {
        if (response.data.success === true) {
          this.assignedKeys.items = response.data.result
        }
      }).finally(() => {
        this.assignedKeys.isLoading = false
      })

      http({
        method: 'get',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.userkeys}/unassigned`
      }).then(response => {
        if (response.data.success === true) {
          this.unassignedKeys.items = response.data.result
        }
      }).finally(() => {
        this.unassignedKeys.isLoading = false
      })
    },
    formatDate (dateString) {
      const date = new Date(dateString)
      return date.toLocaleString()
    },
    onAssignedKeysSortChanged (sortInfo) {
      this.assignedKeys.sortBy = sortInfo.field
      this.assignedKeys.sortDesc = sortInfo.order === 'desc'
    },
    onUnassignedKeysSortChanged (sortInfo) {
      this.unassignedKeys.sortBy = sortInfo.field
      this.unassignedKeys.sortDesc = sortInfo.order === 'desc'
    },
    unassignKey (keyId) {
      this.assignedKeys.isLoading = true
      this.unassignedKeys.isLoading = true

      http({
        method: 'put',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.userkeys}/unassign/${keyId}`
      }).then(response => {
        if (response.data.success === true) {
          this.fetchItems()
        }
      })
    },
    assignKey (keyId) {
      this.assignedKeys.isLoading = true
      this.unassignedKeys.isLoading = true

      http({
        method: 'put',
        url: `/${STOREMODULE_ENDPOINTMODULE_MAP.customer}/api/${STOREMODULE_ENDPOINT_MAP.userkeys}/assign/${keyId}/to/${this.eParent.id}`
      }).then(response => {
        if (response.data.success === true) {
          this.fetchItems()
        }
      })
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eCurrent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eCurrent.key}/definition`]
    },
    entities () {
      return this.$store.getters[`${this.eCurrent.key}/getUnwrapped`]
    }
  }
}
</script>

<style lang="scss"></style>
